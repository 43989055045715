import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { Router, Event as RouterEvent, NavigationStart } from '@angular/router';
import { Shared } from '../shared/shared';
import { Session } from '../session/session';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  ngRemoveLocalStorage($event) {
    localStorage.removeItem('token');
  }

  constructor(private router: Router, public appService: AppService, public shared: Shared, public session: Session) {
    this.shared.isLoading.subscribe((r) => { this.shared.loading = r; });
    this.router.events.subscribe(async (event: RouterEvent) => { await this.ngOnInter(event); });
  }

  ngOnInit(): void { }

  public async ngOnInter(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      if (event.url.includes('/app?tk=')) {
        await this.session.verifySession(event.url);
      } else {
        await this.session.verifyToken();
      }
    }
  }

}
