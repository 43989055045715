import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  coreApiUrl: string = environment.coreApiUrl;

  constructor(private http: HttpClient) { }

  public async verifyToken(token: string) {
    return await this.http.post(this.coreApiUrl + '/Security/ValidateToken', { 'TokenString': token }).toPromise();
  }

}
