import { MatPaginatorIntl } from '@angular/material/paginator';
import { Shared } from '../shared/shared';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {

  OF_LABEL: string = '';

  constructor(public shared: Shared, public translateService: TranslateService) {
    super();

    this.shared.language = translateService.currentLang;
    this.translateService.use(this.shared.language);

    this.translateService.onLangChange.subscribe(() => { this.getPaginatorIntl(); });
    this.getPaginatorIntl();

  }

  getRangeLabel = (page: number, pageSize: number, length: number,) => {

    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;

  };

  getPaginatorIntl() {

    this.translateService.get(['ITEMS_PER_PAGE', 'NEXT_PAGE', 'PREVIOUS_PAGE', 'FIRST_PAGE', 'LAST_PAGE', 'OF_LABEL',]).subscribe(translation => {
      this.itemsPerPageLabel = translation['ITEMS_PER_PAGE'];
      this.nextPageLabel = translation['NEXT_PAGE'];
      this.previousPageLabel = translation['PREVIOUS_PAGE'];
      this.firstPageLabel = translation['FIRST_PAGE'];
      this.lastPageLabel = translation['LAST_PAGE'];
      this.OF_LABEL = translation['OF_LABEL'];
      this.changes.next();
    });

  }

}
