import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shared } from '../shared/shared';

@Injectable()
export class AppInterceptor implements HttpInterceptor {


  private requests: HttpRequest<any>[] = [];


  constructor(private share: Shared) {}


  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(request);

    this.share.isLoading.next(true);

    return Observable.create(observer => {

      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.remove(request);
              observer.next(event);
            }
          },
          err => {
            console.error(err);
            this.remove(request);
            observer.error(err);
          },
          () => {
            this.remove(request);
            observer.complete();
          });

      // remove request from queue when cancelled
      return () => {
        this.remove(request);
        subscription.unsubscribe();
      };

    });

  }

  public remove(request: HttpRequest<any>) {
    try {
      const i = this.requests.indexOf(request);

      if (i >= 0) {
        this.requests.splice(i, 1);
      }

      this.share.isLoading.next(this.requests.length > 0);
    } catch (e) {
      console.error(e);
    }

  }

}
