import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class Shared {

  public complete: Boolean = false; /*Show App Page*/

  public rd: Boolean = false; /*Responsive Design*/
  public screen: Number = 600; /*Size to apply responsive design*/

  public load: Boolean = false; /*Load App Page*/
  public loading: Boolean = false; /*Loading Page*/
  public isLoading = new BehaviorSubject(false);

  public token: string = ''; /*Token User*/
  public user: any = []; /*Info User*/
  public permissionID: number = 0; /*Permission ID*/

  public language: string = 'en'; /*Language App*/
  public main_image: string = ''; /*Main Image App*/

  public hierarchyID: number = 2; /*Hierarchy*/

  public expand: Boolean = true; /*Expand panel*/

}
