import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppInterceptor } from './app.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Shared } from '../shared/shared';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntl } from '../paginator-intl/paginator-intl';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    SlickCarouselModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  entryComponents: [],
  providers: [AppService, Shared, { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }, { provide: MatPaginatorIntl, useClass: PaginatorIntl }],
  bootstrap: [AppComponent]
})
export class AppModule { }
