import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

import { AppComponent } from './app.component';

const routes: Routes = [
  { path: 'app', component: AppComponent },
  { path: 'main', loadChildren: () => import('src/main/main.module').then(m => m.MainModule) },
  { path: 'notfound', loadChildren: () => import('src/notfound/notfound.module').then(m => m.NotFoundModule) },
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: '**', redirectTo: 'notfound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
