import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Shared } from '../shared/shared';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})


export class Session {


  coreLoginUrl: string = environment.coreLoginUrl;


  constructor(public shared: Shared, public sessionService: SessionService, private router: Router) {

  }

  public async verifySession(url: string) {

    if (!crypto.subtle || !TextEncoder) {
      return;
    }

    const spurl = url.split('tk=');

    if (spurl.length < 2) {
      window.location.href = this.coreLoginUrl;
    }

    this.shared.token = spurl[1];
    window.localStorage.setItem('token', this.shared.token);

    await this.sessionService.verifyToken(this.shared.token)
      .then((r) => {

        if (!r) {
          this.shared.complete = false;
          window.location.href = this.coreLoginUrl;
        } else {
          this.shared.user = r['user'];
          this.shared.permissionID = r['permissions'][0]['permissionID'];
          this.shared.complete = true;

          if (this.shared.permissionID == 152) {
            this.shared.language = 'es';
          }

          this.router.navigate(['main/home']);
        }

      }).catch(
        (e) => {
          console.error(e);
          window.location.href = this.coreLoginUrl;
        }
      ).finally(() => { });

  }

  public async verifyToken() {

    if (this.shared.token == '') {
      if (window.localStorage.getItem('token') != null) {
        this.shared.token = window.localStorage.getItem('token');
      }
    }

    if (this.shared.token != '') {

      await this.sessionService.verifyToken(this.shared.token)
        .then((r) => {

          if (!r) {
            this.shared.complete = false;
            window.location.href = this.coreLoginUrl;
          } else {
            this.shared.user = r['user']
            this.shared.permissionID = r['permissions'][0]['permissionID'];
            this.shared.complete = true;

            if (this.shared.permissionID == 152) {
              this.shared.language = 'es';
            }
          }

        }).catch(
          (e) => {
            console.error(e);
            window.location.href = this.coreLoginUrl;
          }
        ).finally(() => { });

    } else {
      window.location.href = this.coreLoginUrl;
    }

  }

}
