// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //coreLoginUrl: 'https://charly-core-securityview-dev.azurewebsites.net/login/MDM', //para publicado en Dev
  ////coreLoginUrl: 'https://charly-core-securityview-dev.azurewebsites.net/login/MdmLocal',//para localhost
  //coreApiUrl: 'https://charly-core-security-dev.azurewebsites.net/api',
  //storage: 'https://straccb2bprd.blob.core.windows.net/'

  coreLoginUrl: 'https://login.charlycloud.com/login/MDM',
  coreApiUrl: 'https://charly-core-security-prd.azurewebsites.net/api',
  storage: 'https://straccb2bprd.blob.core.windows.net/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
