import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/*
 * Get the index.html base tag to know server url.
 */
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
export function getServiceUrl() {
  if (environment.production) {
    return location.protocol + '//' + location.host;
  }
  else {
    return "http://localhost:49232";
    //return "https://charly-core-security-prd.azurewebsites.net/";
  }
}
/*
 * Global definition for BASE_URL to be injected.
 */
const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'SERVICE_URL', useFactory: getServiceUrl, deps: [] },

];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule).catch(err => console.error(err));
